import { clearStorages, setTempToken, setUserData } from '../../utils/storage';
import { loginUser, logoutUser } from '../../utils/api';
import { FETCHED, LOADING } from './constants';

export function login(data, history) {
  return (dispatch) => {
    dispatch(loadingAction(true));

    loginUser(data)
      .then((res) => {
        dispatch(loadingAction(false));
        setTempToken(res.data.temporaryToken);
        setUserData({ username: data.username, isMfaActive: res.data.isMfaActive });
        const qrCountDown = new Date();
        qrCountDown.setMinutes(qrCountDown.getMinutes() + 60);
        if (res.data.isMfaActive) {
          history.push('/mfa/totp', { qrCountDown });
        } else {
          const btnCountDown = new Date();
          btnCountDown.setSeconds(btnCountDown.getSeconds() + 5);
          history.push('/mfa/connect-app', { qrCountDown, btnCountDown });
        }
      })
      .catch((err) => {
        const message =
          err.code >= 400 && err.code < 500
            ? err.message
            : 'Username atau password yang Anda masukan salah';
        dispatch(loginFetchedAction({ code: err.code, message, username: data.username }));
        dispatch(loadingAction(false));
      });
  };
}

export function logout() {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    try {
      await logoutUser();
    } catch ({ message }) {
      dispatch(loginFetchedAction({ message }));
    } finally {
      clearStorages();
      location.href = '/dc/login';
    }
  };
}

function loadingAction(isLoading) {
  return { type: LOADING, isLoading };
}

function loginFetchedAction({ code = 0, message, username = '' }) {
  return { type: FETCHED, code, message, username };
}

export function clearError() {
  return async (dispatch) => {
    dispatch(loginFetchedAction({ message: '' }));
  };
}
