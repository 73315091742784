/* eslint-disable react/jsx-max-depth */
/* eslint-disable no-nested-ternary */
import { Burger, Header, MediaQuery } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { analytics } from '../../../../utils/firebase';
import Banner from '../../../elements/Banner';
import MenuDropdown from '../../../elements/MenuDropdown';
import IconArrowDown from '../../../icons/IconArrowDown';
import IconLogout from '../../../icons/IconLogout';
import { IconProfile } from '../../../icons/IconProfile';
import { renderIconBreadcrumbs } from '../PageBase';
import IconToastr from './IconToastr';
import styles from './styles.scoped.css';


function HeaderLayout({ opened, theme, setOpened, dataDetailUser,
  bcs, toastr, pathname, setModal } ) {
  const history = useHistory();
  const matchesMobile = useMediaQuery('(max-width: 768px)');
  const listSettings = [
    {
      name: 'Profil',
      onClick: () => {
        history.push('/profile');
      },
      icon: <IconProfile />,
    },
    {
      name: 'Log out',
      onClick: () => {
        analytics.logEvent('click_logout', {
          from_path: location.pathname,
        });
        setModal(true);
      },
      icon: <IconLogout />,
    },
  ];
  matchesMobile && listSettings.unshift({
    name: (
      <div className={styles.row}>
        <span>{dataDetailUser.email}</span>
        <small>{dataDetailUser.name}</small>
      </div>
    )
  },);

  const showNameBreadcrumb = (idx, value) => {
    if(matchesMobile && idx !== bcs.length - 1){
      return '...';
    }else if(value.name){
      return value.name;
    }else{
      return value;
    }
  };

  return (
    <>
      <Header className={styles.header} height={{ base: matchesMobile ? 100 : 110 }} hidden={opened} p="md">
        <div className={styles.topContainer}>
          <MediaQuery largerThan="sm" styles={{ display: 'none !important' }}>
            <Burger
              className={styles.menuIcon}
              color={theme?.colors?.dark[6]}
              mr="xl"
              onClick={() => setOpened((o) => !o)}
              opened={opened}
              size="md"
            />
          </MediaQuery>
          <div className={styles.profileContainer}>
            <MediaQuery smallerThan="md" styles={{ display: 'none !important' }}>
              <div className={styles.row}>
                <span>{dataDetailUser.email}</span>
                <small>{dataDetailUser.name}</small>
              </div>
            </MediaQuery>
            <MenuDropdown listDropDown={listSettings} position="bottom-end">
              <IconArrowDown />
            </MenuDropdown>
          </div>
        </div>
        <div className={styles.breadcrumbs}>
          <span>{renderIconBreadcrumbs()}</span>
          {bcs.reduce((acc, b, idx) => {
            if (idx + 1 === bcs.length) {
              return [
                ...acc,
                idx ? (
                  <React.Fragment key={`s${idx}`}>&nbsp;</React.Fragment>
                ) : (
                  ''
                ),
                <span key={idx}>{b}</span>,
              ];
            }
            return [
              ...acc,
              <Link key={idx} to={b?.to ? b.to : `/${pathname.split('/')?.[idx+1]}`}>
                {showNameBreadcrumb(idx, b)}&nbsp;
              </Link>,
              <React.Fragment key={`s${idx}`}>&nbsp;/&nbsp;</React.Fragment>,
            ];
          }, [])}
        </div>
      </Header>
      {toastr?.visible ? (
        <div className={styles.alert}>
          <Banner
            {...toastr}
            icon={<IconToastr type={toastr.type} />}
          />
        </div>
      ) : null}
    </>
  );
}

export default HeaderLayout;

HeaderLayout.defaultProps={
  bcs: [],
  dataDetailUser: {},
  opened: false,
  pathname: '',
  setModal: () => {},
  setOpened: () => {},
  theme: {},
  toastr: {},
};

HeaderLayout.propTypes = {
  bcs: PropTypes.array,
  dataDetailUser: PropTypes.object,
  opened: PropTypes.bool,
  pathname: PropTypes.string,
  setModal: PropTypes.func,
  setOpened: PropTypes.func,
  theme: PropTypes.object,
  toastr: PropTypes.bool,
};
