import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { useSelector } from 'react-redux';
import { Router } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import Base from './components/layouts/PageBase/Base';
import AppContextProvider from './contexts';
import pages from './pages';
import { router } from './utils/routeBuilder';
import { checkExpireTime, clearStorages, getToken } from './utils/storage';

const noAuthRoutes = ['/login', '/forgot-password', '/set-password','/404', '/mfa/scan-qr','/setup-authenticator'];
const assets = ['/assets', '/assets/:id'];
const noAuth = RegExp(noAuthRoutes.join('|')).exec(location.pathname);
const token = getToken();

if (!location.pathname.startsWith('/dc') && location.pathname !== '/404') {
  location.replace('/404');
}
if (location.pathname.startsWith('/dc')) {
  if (!token && !noAuth) {
    sessionStorage.setItem('redirect', location.pathname);
    location.replace('/dc/login');
  } else if (checkExpireTime() && !noAuth) {
    clearStorages();
    location.replace('/dc/login');
  } else if (getToken() && noAuth) {
    location.replace('/dc/first-login');
  }
}

if(assets.includes(location.pathname)) {
  location.replace('/404');
}


const App = ({ history }) => {
  const permissions = useSelector((state) => state.pageBase.dataDetailUser.permitions);

  return(
    <Router history={history}>
      <AppContextProvider>
        <Base>
          {/* <PageBase> */}
          <Switch>
            <Route component={pages.Login} exact path="/login" />
            <Route
              component={pages.ForgotPassword}
              exact
              path="/forgot-password"
            />
            <Route
              component={pages.ResetPassword}
              exact
              path="/set-password"
            />
            <Route component={pages.SetupAuthenticator} exact path="/setup-authenticator" />
            <Route component={pages.ScanCodeMFA} exact path="/mfa/scan-qr" />
            <Route component={pages.MfaConnectApp} exact path="/mfa/connect-app" />
            <Route component={pages.Mfa} exact path="/mfa/totp" />
            <Route component={pages.FirstLogin} exact path="/" />
            <Route component={pages.FirstLogin} exact path="/first-login" />
            <Route component={pages.Profile} exact path="/profile" />
            <Route component={pages.Error404} exact path={'/404'}/>
            {router.map((route) =>
              permissions.map((permission) =>
                route.path === permission.path
                  ? route.elements.map((element) => (
                    <Route
                      component={element.disabled ? pages.Error403 : element.element}
                      exact
                      key={permission.permissionId + element.path}
                      path={element.path}
                    />
                  ))
                  : []
              )
            )}
            <Route component={pages.Error404}/>
          </Switch>
          {/* </PageBase> */}
        </Base>
      </AppContextProvider>
    </Router>
  );};

export default hot(App);

App.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};
